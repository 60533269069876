import React, { PureComponent } from 'react';
import './App.css';
import queryString from 'query-string';
import axios from 'axios';
//import { isThisTypeAnnotation } from '@babel/types';
// Set up Font Awesome


class App extends PureComponent {
  componentDidMount() {

    const initParams = {};
    const INVALID_EMAIL = 'email-invalid';
    const windowAppId = `${process.env.REACT_APP_WINDOW_APP_ID}` ? `${process.env.REACT_APP_WINDOW_APP_ID}` : 'portfolioMapper';

    initParams.token = window[windowAppId] && `${window[windowAppId].token}` && `${window[windowAppId].token}` !== 'undefined' ? `${window[windowAppId].token}` : null;
    initParams.clientEmail = window[windowAppId] && `${window[windowAppId].clientEmail}` && `${window[windowAppId].clientEmail}` !== 'undefined' ? `${window[windowAppId].clientEmail}` : INVALID_EMAIL;
    
    const pathName =  window.location.pathname
    const pathNameArray = pathName.split('/')
    let  last_element = pathNameArray[pathNameArray.length - 1];

    if (last_element.length === 0){
    last_element =  pathNameArray[pathNameArray.length - 2];
    }

    last_element=  last_element.length ===0 ? 'No Event' : last_element
    initParams.subject  = last_element !=='models' ? last_element  : 'No Event';


    saveToSalesforce(initParams);
    // Save the run of loaded funds to Salesforce
    function saveToSalesforce(params) {

    const url = `${process.env.REACT_APP_API_URL}/sfdc/activity/`;
      const headers = {
        Authorization: params.token &&
          `Token ${params.token}`,
      };

      console.log ( 'inside post', initParams)

      // Set up the data to post for the activity
      const data = {
        clientEmail:initParams.clientEmail ,
        activityType: "MAC LandingPage" ,
        subject: initParams.subject,
      };
      console.log ('headers', headers)
      console.log ('data', queryString.stringify(data))
      const request = {
        method: 'POST',
        url: url,
        headers: headers,
        data: data,
      };

      console.log ('request', request)
      return axios(request)
        .then((response) => {
          console.debug(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <div className='app' >
      </div >
    );
  }

}

export default App;
