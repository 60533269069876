
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import './index.css';



const appId = `${process.env.REACT_APP_APP_ID}` ? `${process.env.REACT_APP_APP_ID}` : 'mactracking';

// render
ReactDOM.render(<App /> ,document.getElementById(appId));
